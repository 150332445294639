import "../scss/app.scss";
import Vue from "vue";
import "bootstrap";
import VueScrollClass from "vue-scroll-class";
const carousels = require("./carousels");
import fetchJsonp from "fetch-jsonp";
import axios from "axios";

const app = new Vue({
  el: "#app",
  data: {
    isToggleMenu: false,
    md: false,
    form: {
      category: "お問合せ",
      zipcode: "",
      address1: "愛媛県",
      address2: "",
      address3: "",
    },
    api: "https://graph.facebook.com/v9.0/17841400968245286?fields=name%2Cmedia.limit(9)%7Bcaption%2Clike_count%2Cmedia_url%2Cpermalink%2Ctimestamp%2Cusername%7D&access_token=EAAGbiP88bBQBANq9jbFaXOANZBWNYizZBAS7ZAijMHbmhsSppEACBAi1ZC4ZBrjjEVg3ZCuwGZBZAEZB2nSdwt6kxoWXzdu4HeH3gzBAbqbU8yO3xnUKm4q7v4S1PskEZCuo6e2cSbpAaEVOedZBWQvPCZCZAPQy4bMhnVmOUvnaoMPLgS86oBjIZCuIrJsfs0YJMtGPoZD",
    photos: [],
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth <= 991) {
        this.md = true;
      } else {
        this.md = false;
      }
    },
    onZip: function () {
      let api = "https://zipcloud.ibsnet.co.jp/api/search?zipcode=";
      let param = this.form.zipcode;
      let url = api + param;
      fetchJsonp(url, {
        timeout: 10000, //タイムアウト時間
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.status === 400) {
            //エラー時
            error.textContent = data.message;
          } else if (data.results === null) {
            error.textContent = "郵便番号から住所が見つかりませんでした。";
          } else {
            this.form.address1 = data.results[0].address1;
            this.form.address2 =
              data.results[0].address2 + data.results[0].address3;
          }
        })
        .catch((ex) => {
          //例外処理
          console.log(ex);
        });
      console.log(this.form.zipcode);
    },
    ancLink: function () {
      const anchors = document.querySelectorAll('a[href^="#"]');
      const header = document.querySelector("#header").offsetHeight; //header高さ
      const urlHash = location.hash; // URLのアンカー（#以降の部分）を取得

      // 各 anchor にクリックイベント
      for (let i = 0; i < anchors.length; i++) {
        anchors[i].addEventListener("click", (e) => {
          e.preventDefault(); //デフォルトのクリックイベント無効化

          // 各 anchor の href属性取得
          const href = anchors[i].getAttribute("href");

          // topに戻る以外のアンカー
          if (href !== "#top") {
            // スクロール先の要素を取得 （アンカーの リンク先 #.. の # を取り除いた名前と一致する id名の要素）
            const target = document.getElementById(href.replace("#", ""));

            // スクロール先の要素の位置を取得
            // header の高さ引く
            const position =
              window.pageYOffset + target.getBoundingClientRect().top - header;

            // スクロールアニメーション
            window.scroll({
              top: position, // スクロール先要素の左上までスクロール
              behavior: "smooth", // スクロールアニメーション
            });

            // topに戻る
          } else {
            // スクロールアニメーション
            window.scroll({
              top: 0, // スクロール先
              behavior: "smooth", // スクロールアニメーション
            });
          }
        });
      }
    },
    getPhotos() {
      axios
        .get(this.api)
        .then((response) => {
          this.photos = response.data.media.data;
        })
        .catch((e) => {
          this.photos = "エラーが発生しました。";
        });
    },
  },
  mounted() {
    carousels.carouselSingle();
    carousels.carouselSingleThumbnail();
    carousels.carouselCentering();
    carousels.carouselSideScrolling();
    carousels.carouselCover();
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    this.ancLink();
    this.getPhotos();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  directives: { "scroll-class": VueScrollClass },
});
